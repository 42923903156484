<template>
    <div>
        <v-app-bar>
            <v-toolbar-title dense >Team {{user_data.info.selected_team.name}}</v-toolbar-title>
            <v-spacer></v-spacer>

            <my-list-selector v-if="mode == 'portfolio'" :staticListType="'trader'" @list_selected="e => selected_list = e"></my-list-selector>

            <v-spacer></v-spacer>
            <v-btn class="ma-1" @click="mode = 'portfolio' ">
                <v-icon class="ma-0" v-if="mode== 'portfolio'" >mdi-view-list</v-icon>
                <v-icon class="ma-0" v-else  >mdi-view-list-outline</v-icon>
            </v-btn>

            <v-btn class="ma-1" @click="mode = 'filter' ">
                <v-icon class="ma-0" v-if="mode== 'filter'" >mdi-filter</v-icon>
                <v-icon class="ma-0" v-else  >mdi-filter-outline</v-icon>
            </v-btn>

            <v-icon class="ma-0">mdi-cog</v-icon>
        </v-app-bar>
        
        <v-container fluid v-if="selected_list">
            <v-row v-if="mode == 'portfolio'">
                <v-col cols="12" md="12">
               
                    <v-sheet rounded="sm">
                        <h2> {{selected_list.name}} </h2>
                        <traders-portfolio-listing :listId="selected_list.id"></traders-portfolio-listing>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row v-if="mode == 'filter'">
                <v-col sm="12" md="12">
                    (coming soon)
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import MyListSelector from './static_lists/my-list-selector.vue'
import TradersPortfolioListing from './traders-portfolio-listing.vue'
export default {
    name: 'wallet-porfolios-filters-view',
    components:{
        MyListSelector,
        TradersPortfolioListing
    },
    emits:[
    ],
    data(){return {
        mode:'portfolio',
        selected_list: undefined,
        selected_list: {id:3, name:"good buyers"},
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions(['get_lists_for_team']),
    },
    computed:{
        ...mapState(['user_data']),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>